mark {
  background-color: #00dcd5 !important;
}

.text-muted {
  color: #bdbdbd !important;
}

.text-success {
  color: limegreen;
}

.text-danger {
  color: red;
}

.text-capitalize {
  text-transform: capitalize;
}

.ui.table thead th:hover .table-header-sortable {
  text-shadow: 0 0 8px #00b5ad;
}

.ui.table thead th .table-header-sortable {
  transition: text-shadow 0.2s ease-in-out;
}
