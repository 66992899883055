.td-right {
  text-align: right !important;
}

.td-left {
  text-align: left !important;
}

.td-center {
  text-align: center !important;
}

.tr-header {
  background-color: #f1f1f1;
}

.grey-text {
  color: #bfbfbf !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

a {
  color: dodgerblue;
}

.clickable-color {
  color: dodgerblue;
}

.clickable-folder {
  color: #00b5ad;
}

.mobile-10-relative {
  float: left !important;
  width: 10%;
  position: relative !important;
}

.mobile-15-relative {
  float: left !important;
  width: 15%;
  position: relative !important;
}

.mobile-55-relative {
  float: left !important;
  width: 55%;
  position: relative !important;
}

.mobile-100-relative {
  float: left !important;
  width: 100%;
  position: relative !important;
}

.progress-bar-no-margin {
  margin: 0 !important;
}

.progress-bar-no-margin-responsive {
  margin: 3px 5px 0 !important;
}

.progress-bar-fixed-size-100 {
  min-width: 100px;
}

.progress-bar-partial-margin {
  margin: 0 5px 17px 0 !important;
}
