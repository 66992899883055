.ui.menu .active.item.icon {
  color: #00b5ad;
}

.ui.menu .active.item .download.icon {
  color: #00b5ad;
}

.ui.menu a {
  text-transform: capitalize !important;
}

.ui.menu a.active.item {
  color: #00b5ad;
  text-transform: capitalize !important;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
}

.box .row.content {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.box .row.footer {
  flex: 0 1 40px;
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.transparent-bg {
  background: rgba(255, 255, 255, 0.3) !important;
}

.transparent {
  background: rgba(255, 255, 255, 0) !important;
}

.transparent-table {
  background: rgba(255, 255, 255, 0.85) !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.75;
  }
}

.blink {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}

.blink-fast {
  -webkit-animation: blink 500ms linear infinite;
  -moz-animation: blink 500ms linear infinite;
  -ms-animation: blink 500ms linear infinite;
  -o-animation: blink 500ms linear infinite;
  animation: blink 500ms linear infinite;
}

.shake {
  -webkit-animation-name: shake;
  animation: shake 1.2s ease-in-out infinite both;
  background-color: #b1fdf9 !important;
  color: rgba(0, 181, 173, 0.78) !important;
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
