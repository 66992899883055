@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

.clickable {
  cursor: pointer;
}

.no-margin-top {
  margin-top: 0 !important;
}
