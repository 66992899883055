.subscribe-indexerid-img-td {
  width: 140px;
  padding: 0 !important;
  vertical-align: middle !important;
}

.valign-middle {
  vertical-align: middle !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}
